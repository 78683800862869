<template>
  <div id="ap">
    <a-layout>
      <div class="content-header">
        <a-affix :offset-top="0" style="width: 100%;">
          <a-row type="flex" justify="space-between">
            <a-col span="12">
              <a-button type="primary" @click="ExportEx">导出</a-button>
            </a-col>
            <a-col span="12">
              <a-row type="flex" align="middle" justify="end" :gutter="3">
                <a-col span="12">
                  <a-select v-model="param.organId" style="width:100%" @change="e => {
                    this.param.organId = e;
                    doRefresh();
                  }
                    " placeholder="根据单位筛选">
                    <a-select-option value="">所有单位</a-select-option>
                    <a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{ organ.name
                    }}</a-select-option>
                  </a-select>
                </a-col>
                <a-col span="12">
                  <a-input v-model="param.keyword" allowClear search @pressEnter="getList" @on-clear="getList"
                           placeholder="关键词查询"></a-input>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-affix>
      </div>
    </a-layout>

    <a-layout>
      <a-layout-content>
        <a-table :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false" :indentSize="25"
                 :style="{ background: '#FFF' }" size="small" row-key="storeId" border :columns="keys" ref="list"
                 :loading="loading" :data-source="data.records">
          <template slot-scope="row" slot="action">
            <a-button-group shape="circle" size="small">
              <a-tooltip v-if="row.status == '待审核'"><a-button type="primary" v-has="'store:audit'"
                          @click="showModal(row)">待审核</a-button></a-tooltip>
              <a-tooltip v-if="row.status == '正常'"><a-button>已审核</a-button></a-tooltip>
              <a-tooltip :title="'驳回原因:' + row.cause" v-if="row.status == '驳回'">
                <a-button type="danger">驳回</a-button></a-tooltip>
            </a-button-group>
            <a-button-group v-has="'store:del'" shape="circle" size="small">
              <a-tooltip title="删除">
                <a-button type="link" icon="delete" @click="del(row.storeId)"></a-button>
              </a-tooltip>
            </a-button-group>
          </template>
        </a-table>
      </a-layout-content>
    </a-layout>
    <a-row>
      <a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
        <a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext" @showSizeChange="setLimit"
                      :current="data.current" />
      </a-col>
    </a-row>
    <div>
      <a-modal title="审批" cancelText="取消审批" okText="确认审批" :visible="visible" :confirm-loading="confirmLoading"
               @ok="handleOk" @cancel="handleCancel">
        <a-form-model>
          <a-row :gutter="15">
            <a-col span="8">
              <a-form-model-item label="请选择是否通过审批" label-position="top">
                <a-switch v-model="isExamine" />
              </a-form-model-item>
            </a-col>
            <a-col span="8">
              <a-form-model-item v-if="!isExamine" label="不通过原因" label-position="top">
                <a-input v-model="cause" placeholder="请输入不通过原因"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </div>
    <a-back-top />


  </div>
</template>

<script>
import apiUtil from "@/common/apiUtil";
import ExporttoExcel from "@/common/ExportExcel.js";
import utils from '@/common/utils';
export default {
  data() {
    return {
      confirmLoading: false,
      visible: false,
      loading: false,
      isExamine: true,
      cause: '',
      storeId: null,
      status: '',
      data: [],
      param: {
        page: 1,
        limit: 20,
        keyword: '',
        organId: ''
      },
      organList: apiUtil.getOrganList(),
      keys: [
        // { title: 'ID', dataIndex: 'storeId', width: 100, align: 'center' },
        { title: '商户名称', dataIndex: 'name', width: 160, ellipsis: true },
        { title: '负责人', dataIndex: 'person', width: 80, ellipsis: true },
        { title: '手机号', dataIndex: 'phone', width: 120, ellipsis: true },
        { title: '地址', dataIndex: 'address', width: 200, ellipsis: true },
        { title: '提交日期', dataIndex: 'createDate', width: 100, ellipsis: true },
        { title: '所属单位', dataIndex: 'organName', width: 100, ellipsis: true },
        { title: '备注', dataIndex: 'remark', width: 260, ellipsis: true },
        { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
      ]
    };
  },
  created() {
    this.getList();
  },
  methods: {
    //删除
    del(id) {
      this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
        utils.showSpin();
        this.http.delete('/platform/store/delete/' + id).then(ret => {
          if (ret.code == 200) {
            this.utils.success('操作成功！').then(() => {
              this.getList();
            });
          }
        });
      });
    },
    // 获取数据
    getList() {
      this.loading = true;
      this.http
        .get('/platform/store/list', this.param)
        .then(ret => {
          this.data = ret.data;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
        });
    },
    // 导出excel
    ExportEx() {
      if (this.data.records.length > 0) {
        const filterData = this.data.records.map((item) => {
          return { '商户名称': item.name, "负责人": item.person, "手机号": item.phone, "地址": item.address, "提交日期": item.createDate, "所属单位": item.organName, "备注": item.remark, "状态": item.status };
        });
        const organ = apiUtil.getOrganList().filter(item => item.organId == this.param.organId);
        let organName = '';
        if (organ.length == 0) {
          organName = "所有单位";
        } else {
          organName = organ[0].name;
        }
        ExporttoExcel(filterData, `${organName}商户记录表.xlsx`);
      } else {
        this.$message.info('没有数据哦！！!');
        return;
      }
    },
    handleChange(e) {
      console.log(e);
    },
    // 点击审批
    showModal(e) {
      //console.log(e);
      this.isExamine = true;
      this.status = '正常';
      this.storeId = e.storeId;
      this.visible = true;
    },
    // 确认审批
    handleOk(e) {
      //console.log({storeId: this.storeId, status: this.status, cause: this.cause})
      if (this.isExamine == false) {
        this.status = '驳回';
      }
      this.http
        .post('/platform/store/audit', { storeId: this.storeId, status: this.status, cause: this.cause })
        .then(ret => {
          if (ret.code == 200) {
            this.utils.success('操作成功！').then(() => {
              this.getList();
            });
          }
          this.visible = false;
        })
        .catch(err => {
          this.visible = false;
        });
    },
    doRefresh() {
      this.param.page = 1;
      this.getList();
    },
    // 取消审批
    handleCancel(e) {
      this.visible = false;
    },
    //设置每页的显示条数
    setLimit(current, limit) {
      this.param.page = 1;
      this.param.limit = limit;
    },
    //页码切换
    getNext(page) {
      this.param.page = page;
      this.getList();
    }
  }
};
</script>

<style scoped></style>
